import {Injectable} from '@angular/core';
import {GraphApiService} from "./graph-api.service";
import {BehaviorSubject} from "rxjs";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class DepartmentService {
  private readonly storageKey = 'department';
  private readonly availableDepartments = [
    'DTSTR',
    'DTSEI',
    'DTCBO',
    'DTNRE',
    'DTNPC',
    'DTRHS',
    'DTSOU',
    'SIEGE'
  ];
  public readonly defaultDepartment = 'SIEGE';
  private readonly expiryTime = 48 * 60 * 60 * 1000; // 48h

  private departmentSubject = new BehaviorSubject(
    <string>this.getDepartmentFromLocalStorage(false) ?? this.defaultDepartment
  );
  public department$ = this.departmentSubject.asObservable();

  constructor(private graphApiService: GraphApiService) {
  }

  /**
   * Efface la donnée de département du localStorage
   * @private
   */
  private deleteDepartmentFromLocalStorage(): void {
    localStorage.removeItem(this.storageKey);
  }

  /**
   * Récupère le département stocké en localStorage et vérifie si la donnée est valide
   * @param checkExpiryTime Vérifie si la donnée a expiré ou non
   * @private
   */
  public getDepartmentFromLocalStorage(checkExpiryTime: boolean = true): string | null {
    try {
      const department = JSON.parse(localStorage.getItem(this.storageKey) ?? '');
      if (department?.value && department?.date && this.checkDepartmentValue(department.value)) {
        if (checkExpiryTime) {
          if (new Date().getTime() - new Date(department.date).getTime() < this.expiryTime) {
            return department.value;
          } else {
            return null;
          }
        } else {
          return department.value;
        }
      }
      return null;
    } catch (e) {
      return null;
    }
  }

  /**
   * Stocke le département dans le localStorage et notifie les abonnés à l'observable
   * @param department
   * @private
   */
  private setDepartment(department: string): void {
    const departmentValue = department.toUpperCase().trim();
    localStorage.setItem(this.storageKey, JSON.stringify({value: department, date: new Date().toISOString()}));
    this.departmentSubject.next(departmentValue);
  }

  /**
   * Vérifie si le département a une valeur valide et supprime la donnée du localStorage si ce n'est pas le cas
   * @param department
   * @private
   */
  private checkDepartmentValue(department: string): boolean {
    if (!this.availableDepartments.includes(department)) {
      this.deleteDepartmentFromLocalStorage();
      return false;
    }
    return true;
  }

  /**
   * Récupère le département de l'utilisateur connecté à partir du localStorage ou de l'API Graph
   * et le stocke dans le localStorage puis notifie les abonnés à l'observable
   */
  public async loadDepartment() {
    // Si la donnée en stockage local est valide, on la retourne
    const lsDepartment = this.getDepartmentFromLocalStorage();
    if (lsDepartment) {
      this.departmentSubject.next(lsDepartment);
      return;
    }

    // Sinon, on tente de récupérer le département depuis l'API Graph
    try {
      const me = await this.graphApiService.getMe();
      environment.enableLogging && console.log('me', me);
      // Si le département n'est pas renseigné, on retourne extensionAttribute ou la valeur par défaut
      const department = me.department?.toUpperCase().trim() ??
        me.onPremisesExtensionAttributes?.extensionAttribute1?.toUpperCase().trim() ??
        this.defaultDepartment;
      // Si le département est valide, on le stocke dans le localStorage, sinon on stocke la valeur par défaut
      if (this.checkDepartmentValue(department)) {
        this.setDepartment(department);
      } else {
        this.setDepartment(this.defaultDepartment);
      }
    } catch (error) {
      // Si erreur de l'api graph on garde l'ancienne valeur du département ou on set la par défaut
      environment.enableLogging && console.log('error graph', error)
      let errorDepartment = lsDepartment ?? this.defaultDepartment;
      this.setDepartment(errorDepartment);
    }
  }

}
