<div class="search-results__item"
     [class.cursor-pointer]="isPointerActive()"
     data-type="document" (click)="openDocumentUrl($event)">
    <div class="tag_list">
        <span class="tag bg-blue">DOCUMENT</span>
        <span *ngIf="document.perimetre && document.perimetre != ''" class="tag tag-section">
            {{ document.perimetre }}
        </span>
        <span *ngIf="document.nature_du_document && document.nature_du_document != ''" class="tag tag-section">
            {{ document.nature_du_document }}
        </span>
    </div>
    <p class="search-results__title">{{ document.name }}</p>
    <div class="search-results__content">
        <span class="search-results__date">{{ document.date_d_effet|date: 'dd/MM/y' }}</span>
        <p class="search-results__text">{{ document.description }}</p>
        <button #optionsButton class="search-results__options" (click)="toggleOptions()">Activer les options</button>
        <div #options class="options" [class.active]="isOptionsActive">
            <ul class="options_list">
                <li class="options_list--item"><a (click)="openDialogShareByEmail()">Envoyer par mail</a></li>
                <li class="options_list--item"><a (click)="copyUrlToClipboard()">Copier le lien</a></li>
                <li class="options_list--item">
                  <button (click)="openDialogMedia()">Propriétés du document</button>
                </li>
            </ul>
        </div>
    </div>
</div>

