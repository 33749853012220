<div class="participation" *ngIf="appelsParticipations.length > 0">

  <div class="participation__list" [ngClass]="{'one-slide': appelsParticipations.length === 1}" >
    <swiper-container #participationSlider class="swiper-container" init="false">

      @for (appel of appelsParticipations; track appel.id) {
        <swiper-slide class="participation__item">
            <span *ngIf="appel.perimetre === 'Siège'" class="tag bg-darkblue">{{appel.perimetre}}</span>
            <span *ngIf="appel.dt" class="tag bg-darkblue">{{'DT '+appel.dt}}</span>
            <p class="participation__participate">Participez !</p>
            <p class="participation__title" [innerHTML]="appel.title"></p>
            <p class="participation__text" [innerHTML]="appel.chapo"></p>
            <a href="{{appel.lien?.url}}" class="btn btn--gradient">C'est parti</a>
        </swiper-slide>
      }

    </swiper-container>

    <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div>
  </div>
</div>
