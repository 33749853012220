<div class="mes-apps">
  <app-loading-overlay [isLoading]="isLoading"></app-loading-overlay>
  <div class="news-headline-hp">
    <h2 class="title-icons-underline"><span class="icon vnf-icons-icon-apps"></span>Mes Apps</h2>
  </div>


  <p class="line-1">Mes Apps métier à mettre en favori dans le menu.</p>
  <p class="line-2">Je clique sur une app pour la mettre en favoris et la déclique pour l’enlever, c’est à vous.</p>

    <mat-form-field>
        <mat-label>Toutes les Apps</mat-label>
        <mat-select (selectionChange)="onSelectionChange($event)">
            <mat-option
                    *ngFor="let filtre of filtres"
                    [value]="filtre.slug">
                {{ filtre.name }}
            </mat-option>
        </mat-select>
    </mat-form-field>

  <div class="mes-apps-list">
    <div class="mes-apps-list__item {{app.isFavorite? 'favorite' : ''}}" [attr.id]="'appItem' + i"
      *ngFor="let app of apps; let i = index">
      <a class="mes-apps__btn-favorite" href="#" (click)="toggleFavorite($event, app.flagId)">Bouton favori</a>
      <p [innerHTML]="app.title"></p>
    </div>
  </div>

  <app-pagination [currentPage]="currentPage" [totalPages]="totalPages" (pageChanged)="onPageChanged($event)"
    [isNbElementsChangeable]="true" (limitChanged)="onLimitChanged($event)" [listLimit]="[50, 100]"></app-pagination>

  <!--
  <div class="d-flex j-center">
    <a href="#" class="btn btn--primary">Enregistrer</a>
  </div> -->

</div>