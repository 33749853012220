import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { PdfJsViewerModule } from "ng2-pdfjs-viewer";
import { CustomInnerHtmlDirective } from "../../../directives/custom-inner-html.directive";
import { FlexibleVideo } from '../../../models/flexibles';
import { Visuel } from '../../../models/visuel';
import { FlexibleVideoComponent } from '../../flexible/flexible-video/flexible-video.component';
import {NgIf, NgOptimizedImage} from '@angular/common';
import { Link } from '../../../models/link';

@Component({
  selector: 'app-dialog-text-img-video',
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, FlexibleVideoComponent, CustomInnerHtmlDirective, PdfJsViewerModule, NgIf, NgOptimizedImage],
  templateUrl: './dialog-text-img-video.component.html',
  styleUrl: './dialog-text-img-video.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogTextImgVideoComponent {
  title!: string;
  content!: string;
  button: Link | null = null;
  flexibleVideo: FlexibleVideo | null = null;
  visuel: Visuel | null = null;
  infographie: any = null;
  lien_infographie: any = null;
  typeInfographie!: string;
  isPdf: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.title = this.data.title;
    this.content = this.data.content;
    this.button = this.data.button;
    this.visuel = this.data.visuel;
    this.infographie = this.data.infographie;
    this.lien_infographie = this.data.lien_infographie;
    this.flexibleVideo = this.data.flexibleVideo;
    this.typeInfographie = this.data.typeInfographie

    this.isPdf = (this.typeInfographie === 'mediatheque')
      ? this.checkPdfUrl(this.infographie?.url)
      : this.checkPdfUrl(this.lien_infographie);
  }

  checkPdfUrl(url?: string): boolean {
    if (!url) return false; // Gérer les valeurs null ou undefined
    try {
      const urlObj = new URL(url);
      urlObj.search = ''; // Supprime les paramètres de requête
      return urlObj.pathname.toLowerCase().endsWith('.pdf');
    } catch (e) {
      return false; // Gérer les URL invalides
    }
  }

}
