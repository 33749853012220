import {
  AfterViewInit,
  ChangeDetectorRef,
  OnChanges,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  QueryList,
  ViewChildren,
  Input,
} from '@angular/core';
// @ts-ignore

import Swiper, { Navigation } from 'swiper';
import { AppelsParticipation } from '../../models/appels-participation';
import { NgIf, NgClass } from '@angular/common';

@Component({
  selector: 'app-participation',
  standalone: true,
  imports: [NgIf, NgClass],
  templateUrl: './participation.component.html',
  styleUrl: './participation.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ParticipationComponent implements AfterViewInit, OnChanges {
  @Input() appelsParticipations: AppelsParticipation[] = [];
  @ViewChildren('participationSlider', { read: ElementRef }) participationSliders!: QueryList<ElementRef>;

  constructor(private cdr: ChangeDetectorRef) { }

  async ngOnInit(): Promise<void> {

  }

  // Detect changes when the input 'breves' is updated
  ngOnChanges(): void {
    this.cdr.detectChanges();
    this.initSliderParticipation();
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
    this.initSliderParticipation();
  }

  initSliderParticipation(): void {
    this.participationSliders.forEach((participationSlider) => {
      const swiperEl = participationSlider.nativeElement;

      if (swiperEl.swiper) {
        swiperEl.swiper.update();
      } else {
        this.initializeSwiper(swiperEl);
      }
    });
  }

  initializeSwiper(swiperEl: any): void {
    // Swiper parameters
    const swiperParams = {
      slidesPerView: '1',
      direction: 'horizontal',
      loop: false,
      speed: 500,
      spaceBetween: 10,
      simulateTouch: true,  // Active les événements tactiles (inclut trackpad)
      allowTouchMove: true, // Permet le mouvement de swipe
      touchEventsTarget: 'container',  // Les événements sont capturés sur tout le conteneur
      cssMode: true, // Mode de défilement natif, fonctionne bien avec le trackpad
      pagination: {
        clickable: true,
      },
      navigation: {
        nextEl: '.participation .swiper-button-next',
        prevEl: '.participation .swiper-button-prev',
      },
    };
    // Assign the parameters to the container
    Object.assign(swiperEl, swiperParams);

    // Initialize Swiper with the parameters
    swiperEl.initialize();
  }
}
