import {Component, ElementRef, HostListener, ViewChild} from '@angular/core';
import {KeyValuePipe, NgForOf, UpperCasePipe} from "@angular/common";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatOption} from "@angular/material/autocomplete";
import {MatSelect} from "@angular/material/select";
import {ApiService} from '../../services/api.service';
import {environment} from '../../../environments/environment';
import {useCache} from '../../utils/utils.service';
import {App} from '../../models/app';
import {PaginationComponent} from '../../components/pagination/pagination.component';
import {ActivatedRoute} from '@angular/router';
import {LoadingOverlayComponent} from '../../components/loading-overlay/loading-overlay.component';

@Component({
  selector: 'app-mes-apps',
  standalone: true,
  templateUrl: './mes-apps.component.html',
  imports: [
    KeyValuePipe,
    MatFormField,
    MatLabel,
    MatOption,
    MatSelect,
    NgForOf,
    UpperCasePipe,
    PaginationComponent,
    LoadingOverlayComponent
  ],
  styleUrls: ['./mes-apps.component.scss']
})
export class MesAppsComponent {
  public isLoading: boolean = false;
  isOptionsActive = false;
  apps: App[] = [];
  filtres: { slug: string, name: string }[] = [];
  selectedSlug: string = '';
  currentPage = 1;
  limit = 50;
  totalPages = 0;

  @ViewChild('optionsOpener', {static: false}) optionsOpener!: ElementRef;
  @ViewChild('optionsMenu', {static: false}) optionsMenu!: ElementRef;

  constructor(private apiService: ApiService, private route: ActivatedRoute,) {
  }

  ngOnInit(): void {
    // Récupérer les paramètres GET de la route actuelle
    this.route.queryParams.subscribe(params => {
      this.currentPage = !isNaN(params['p']) ? +params['p'] : this.currentPage; // récupérer le numéro de la page
      this.limit = !isNaN(params['limit']) ? +params['limit'] : this.limit; // récupérer le nombre d'éléments par page
    });

    // load apps data
    useCache('apps', this, async () => {
      // load Breves, Events and Home data
      await Promise.all([
        this.getApps(this.currentPage, this.limit),
      ]);
    }, () => {
      return {
        apps: this.apps,
      }
    });
  }

  async getApps(page: number, limit: number = 10): Promise<void> {
    const {body, headers} = await this.apiService.getApps({
      page: this.currentPage,
      limit: this.limit,
      ...(this.selectedSlug !== '' && {categorie: this.selectedSlug})
    });
    // transform value
    if (body) {
      this.apps = this.formatApps(body.data);
      this.filtres = [{slug: '', name: 'Toutes les Apps'}, ...body.filtres];
      this.totalPages = headers.get('x-wp-totalpages');
    }
    environment.enableLogging && console.log('apps', body, headers);

    this.isLoading = false;
  }

  formatApps(data: any[]): App[] {
    const apps: App[] = [];
    try {
      data.forEach((item: any) => {
        apps.push({
          id: item.id,
          flagId: item.flag_id,
          title: item.title.rendered,
          category: {
            name: item.acf?.categorie_application_transverse?.name,
            slug: item.acf?.categorie_application_transverse?.slug
          },
          lien: {url: item.acf?.lien},
          isDefault: item.acf?.application_par_defaut,
          isFavorite: item.is_favorite
        });
      });
    } catch (e) {
      environment.enableLogging && console.error('formatApps', e);
    }
    return apps;
  }

  toggleFavorite(event: Event, flagId: number): void {
    event.preventDefault();
    const target = event.target as HTMLElement;
    const parent = target.parentElement as HTMLElement;
    const flag = parent.classList.toggle('favorite');
    // Update favorite status
    if (flag) {
      // Add to favorite
      this.apiService.flag('apps', flagId).then((e) => {
        environment.enableLogging && console.log('flag', e);
      });
    } else {
      // Remove to favorite
      this.apiService.unflag('apps', flagId).then((e) => {
        environment.enableLogging && console.log('unflag', e);
      });
    }
  }

  onSelectionChange(event: any) {
    this.selectedSlug = event.value;
    this.currentPage = 1;
    this.getApps(this.currentPage, this.limit);
  }

  toggleOptions(): void {
    this.isOptionsActive = !this.isOptionsActive;
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent): void {
    if (this.optionsOpener && this.optionsMenu) {
      const targetElement = event.target as HTMLElement;
      const clickedInsideOpener = this.optionsOpener.nativeElement.contains(targetElement);
      const clickedInsideMenu = this.optionsMenu.nativeElement.contains(targetElement);

      if (!clickedInsideOpener && !clickedInsideMenu) {
        this.isOptionsActive = false;
      }
    }
  }

  onPageChanged(page: number) {
    this.isLoading = true;
    this.currentPage = page;
    this.getApps(this.currentPage, this.limit);
  }

  onLimitChanged(limit: number) {
    this.isLoading = true;
    this.limit = limit;
    this.getApps(this.currentPage, this.limit);
  }
}
