import {Component, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {RouterLink} from "@angular/router";
import {
  MatCalendarCellClassFunction,
  MatDatepicker,
  MatDatepickerInput,
  MatDatepickerModule,
  MatDatepickerToggle,
  MatDateRangeInput
} from "@angular/material/datepicker";
import {environment} from "../../../../environments/environment";
import {NgClass, NgForOf, NgIf, TitleCasePipe} from "@angular/common";
import {MatFormField, MatFormFieldModule} from "@angular/material/form-field";
import {MatNativeDateModule} from "@angular/material/core";
import {MatInputModule} from "@angular/material/input";
import {SearchFilter} from "../../../models/search-filters";

type FilterKeys = 'themes' | 'perimetres' | 'natures';

@Component({
  selector: 'app-filters-bar',
  standalone: true,
  imports: [
    NgClass,
    MatFormField,
    MatDatepickerToggle,
    MatDatepicker,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatDatepickerInput,
    RouterLink,
    NgForOf,
    NgIf,
    TitleCasePipe
  ],
  templateUrl: './filters-bar.component.html',
  styleUrl: './filters-bar.component.scss'
})
export class FiltersBarComponent {
  @Input() filtres: {
    perimetres: SearchFilter[] | null,
    themes: SearchFilter[] | null,
    natures: SearchFilter[] | null,
    startDate: Date | null,
    endDate: Date | null
  } = {perimetres: null, themes: [], natures: [], startDate: null, endDate: null};
  @Output() filtresChange = new EventEmitter<any>();
  @Output() searchTriggerred = new EventEmitter<any>();

  openDropdownIndex: number | null = null;
  openedToggles: string[] = [];

  async onDateChange(dateRangeInput: MatDateRangeInput<Date>) {
    // Mettre à jour les dates de début et de fin
    this.filtres.startDate = dateRangeInput.value?.start ? new Date(dateRangeInput.value?.start) : null;
    this.filtres.endDate = dateRangeInput.value?.end ? new Date(dateRangeInput.value?.end) : null;

    environment.enableLogging && console.log(this.filtres.startDate, this.filtres.endDate);
    this.filtresChange.emit(this.filtres);
  }

  dateClass: MatCalendarCellClassFunction<Date> = (cellDate, view) => {
    // Only highligh dates inside the month view.
    if (view === 'month') {
      const date = cellDate.getDate();

      return date === 1 || date === 20 ? 'example-custom-date-class' : '';
    }

    return '';
  };

  openToggle( id: string ): void
  {
    if (this.openedToggles.includes(id)) {
      this.openedToggles = this.openedToggles.filter((toggleId) => toggleId !== id);
    } else {
      this.openedToggles.push(id);
    }
  }
  closeToggles(): void
  {
    this.openedToggles = [];
  }

  asFilterKey(value: string): FilterKeys {
    if (value === 'themes' || value === 'perimetres' || value === 'natures') {
      return value;
    }
    throw new Error(`Invalid filter key: ${value}`);
  }

  onCheckboxChange(event: any, index: number, type: string, subIndex?: number) {
    if (type === 'themes' && this.filtres.themes) {
      this.filtres.themes[index].checked = event.target.checked;
    } else if (type === 'perimetres' && this.filtres.perimetres) {
      this.filtres.perimetres[index].checked = event.target.checked;
    } else if (type === 'perimetre-subfilter' && subIndex !== undefined && subIndex !== null && this.filtres.perimetres) {
      this.filtres.perimetres[index].sub_filters[subIndex].checked = event.target.checked;
    } else if (type === 'natures' && this.filtres.natures) {
      this.filtres.natures[index].checked = event.target.checked;
    } else if (type === 'nature-subfilter' && subIndex !== undefined && subIndex !== null && this.filtres.natures) {
      this.filtres.natures[index].sub_filters[subIndex].checked = event.target.checked;
    }
    this.filtresChange.emit(this.filtres);
  }

  selectAll(type: FilterKeys) {
    this.filtres[type]?.forEach(checkbox => {
      if (checkbox.sub_filters) {
        checkbox.sub_filters.forEach(subFilter => {
          subFilter.checked = true;
        });
      } else {
        checkbox.checked = true;
      }
    });

    this.filtresChange.emit(this.filtres);
  }

  unselectAll(type: FilterKeys) {
    this.filtres[type]?.forEach(checkbox => {
      if (checkbox.sub_filters) {
        checkbox.sub_filters.forEach(subFilter => {
          subFilter.checked = false;
        });
      } else {
        checkbox.checked = false;
      }
    });
    this.filtresChange.emit(this.filtres);
  }

  isAnyChecked(type: FilterKeys): boolean {
    return this.filtres[type]?.some(checkbox => {
      if (checkbox.checked) {
        return true;
      }
      if (checkbox.sub_filters) {
        return checkbox.sub_filters.some(subFilter => subFilter.checked);
      }
      return false;
    }) ?? false;
  }


  getSelectedLabel(type: FilterKeys): string {
    const selected = this.filtres[type]?.flatMap(checkbox => {
      if (checkbox.active) {
        return [checkbox];
      }
      if (checkbox.sub_filters) {
        return checkbox.sub_filters.filter(subFilter => subFilter.active);
      }
      return [];
    });

    if (selected && selected.length > 0) {
      const firstSelected = selected[0].name;
      const additionalCount = selected.length - 1;
      return additionalCount > 0 ? `${firstSelected}... (+${additionalCount})` : firstSelected;
    }
    return 'Tous';
  }

  activateSelectedFilters(): void {
    const filterKeys: FilterKeys[] = ['themes', 'perimetres', 'natures'];
    filterKeys.forEach((key: FilterKeys) => {
      this.filtres[key]?.forEach(checkbox => {
        checkbox.active = checkbox.checked;
        if (checkbox.sub_filters) {
          checkbox.sub_filters.forEach(subFilter => {
            subFilter.active = subFilter.checked
          });
        }
      });
    });
  }



  search(): void {
    this.activateSelectedFilters();
    this.closeDropdown();
    this.searchTriggerred.emit();
  }

  toggleDropdown(index: number) {
    this.openDropdownIndex = this.openDropdownIndex === index ? null : index; // Ouvre ou ferme le dropdown
  }

  @HostListener('document:click', ['$event'])
  handleClickOutside(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const popinElements = document.querySelectorAll('.filters-dropdown__popin');
    const openerElements = document.querySelectorAll('.filters-dropdown__opener');

    const isInsidePopin = Array.from(popinElements).some(popin => popin.contains(target));
    const isOpenerClicked = Array.from(openerElements).some(opener => opener.contains(target));

    if (this.openDropdownIndex !== null && !isInsidePopin && !isOpenerClicked) {
      this.closeDropdown();
    }
  }

  closeDropdown() {
    this.openDropdownIndex = null;
  }

}
