import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {BehaviorSubject, Observable, tap} from "rxjs";
import {MsalService} from "@azure/msal-angular";
import {SearchResult} from "../models/search-result";
import {environment} from "../../environments/environment";


@Injectable({
  providedIn: 'root'
})
export class ElasticsearchService {
  private readonly elasticsearchUrl: string;
  private readonly apiKey: string;
  private readonly searchIndex: string;
  private readonly searchHistoryDataStream: string;

  private searchResults = new BehaviorSubject<SearchResult>({
    searchQuery: '',
    total: 0,
    hits: [],
    facets: {},
    hasFilters: false,
    appliedFilters: {}
  });
  searchResults$ = this.searchResults.asObservable();

  private perPage = new BehaviorSubject<number>(5); // Default value
  perPage$ = this.perPage.asObservable();

  constructor(private http: HttpClient, private msalService: MsalService) {
    this.elasticsearchUrl = environment.elasticSearchConfig.elasticsearchUrl;
    this.apiKey = environment.elasticSearchConfig.apiKey;
    this.searchIndex = environment.elasticSearchConfig.searchIndex;
    this.searchHistoryDataStream = environment.elasticSearchConfig.searchHistoryDataStream;
  }

  escape(query: string): string {
    const specialCharacters = ['\\', '+', '-', '!', '(', ')', ':', '^', '[', ']', '"', '{', '}', '~', '*', '?', '|', '&', '/', '.'];
    let escapedQuery = '';

    for (const char of query) {
      // Vérifie si le caractère est spécial et doit être échappé
      if (specialCharacters.includes(char)) {
        escapedQuery += '\\'; // Ajoute un backslash devant le caractère spécial
      }
      escapedQuery += char; // Ajoute le caractère à la chaîne échappée
    }

    return escapedQuery;
  }

  // Fonction pour récupérer les champs de l'index de recherche
  getSearchIndexFields(): Observable<any> {
    const url = `${this.elasticsearchUrl}/${this.searchIndex}/_mapping`; // URL de l'API Elasticsearch
    const headers = new HttpHeaders({
      'Authorization': `ApiKey ${this.apiKey}` // Inclure la clé API dans les en-têtes
    });
    return this.http.get(url, {headers}); // Passer les en-têtes dans la requête
  }


  queryDocuments(query: string, size: number = 5, page: number = 1, filtres: any = null): Observable<any> {
    const url = `${this.elasticsearchUrl}/${this.searchIndex}/_search`; // URL de l'API Elasticsearch
    const headers = new HttpHeaders({
      'Authorization': `ApiKey ${this.apiKey}` // Inclure la clé API dans les en-têtes
    });

    // Définition de la structure de la requête
    const body: any = {
      query: {
        bool: {
          must: [
            {
              query_string: {
                query: query // Requête de recherche
              }
            }
          ],
          filter: []
        }
      },
      aggs: {
        natureDistribution: {
          terms: {
            field: "nature_du_document"
          }
        },
        perimetreDistribution: {
          terms: {
            field: "perimetre"
          }
        },
      },
      from: (page - 1) * size, // Index de départ,
      size: size
    };


    if (filtres?.natures?.length > 0) {
      const natureFilters: string[] = [];

      // Récupérer les natures directement cochées et leurs subfilters cochés
      filtres.natures.forEach((filter: any) => {
        if (filter.checked) {
          natureFilters.push(filter.name);
        }

        // Vérifier et inclure les subfilters cochés
        if (filter.sub_filters?.length > 0) {
          filter.sub_filters.forEach((subFilter: any) => {
            if (subFilter.checked) {
              natureFilters.push(subFilter.name);
            }
          });
        }
      });

      if (natureFilters.length > 0) {
        body.query.bool.filter.push({
          bool: {
            should: natureFilters.map((nature: string) => ({
              term: { "nature_du_document": nature }
            })),
            minimum_should_match: 1 // Assure qu'un des natures soit présent
          }
        });
      }
    }

    if (filtres?.perimetres?.length > 0) {
      const perimetreFilters = filtres.perimetres
        .filter((filter: any) => filter.checked)
        .map((filter: any) => filter.name);

      if (perimetreFilters.length > 0) {
        body.query.bool.filter.push({
          bool: {
            should: perimetreFilters.map((perimetre: string) => ({
              term: { "perimetre": perimetre }
            })),
            minimum_should_match: 1 // Assure qu'un des perimetre soit présent
          }
        });
      }
    }

    // Vérifie s'il y a au moins un filtre utilisé dans la requête
    const hasFilters = body.query.bool.filter.length > 0;


    return this.http.post(url, body, { headers }).pipe(
      tap((data: any) => {
        const hits = data.hits.hits.map((hit: any) => hit._source);
        const total = data.hits.total.value;

        // Mise à jour des résultats avec les facettes et les filtres
        this.searchResults.next({
          searchQuery: query,
          total: total,
          hits: hits,
          facets: {
            natureDistribution: data.aggregations.natureDistribution.buckets,
            perimetreDistribution: data.aggregations.perimetreDistribution.buckets
          },
          hasFilters: hasFilters,
          appliedFilters: filtres
        });
      })
    );
  }

  saveNewHistorySearch(search_query: string): Observable<any> {
    const url = `${this.elasticsearchUrl}/${this.searchHistoryDataStream}/_doc`;
    const headers = new HttpHeaders({
      'Authorization': `ApiKey ${this.apiKey}`
    });

    const user_id = this.msalService.instance.getActiveAccount()?.idTokenClaims?.oid;

    if (user_id) {
      const body = {
        user_id, // Ajouter l'ID de l'utilisateur
        search_query, // Ajouter la requête de recherche
        '@timestamp': new Date().toISOString() // Ajouter la date actuelle
      };
      return this.http.post<any>(url, body, {headers})
    } else {
      return new Observable((observer) => {
        observer.error('No user ID found');
      })
    }

  }

  searchDocumentsByName(query: string, size: number = 5): Observable<any> {
    const url = `${this.elasticsearchUrl}/${this.searchIndex}/_search`;
    const headers = new HttpHeaders({
      'Authorization': `ApiKey ${this.apiKey}`
    });
    const escapedQuery = this.escape(query);
    const body = {
      query: {
        query_string: {
          fields: ['name'], // Rechercher dans le champ 'name'
          query: `*${escapedQuery}*` // Rechercher le nom du document
        }
      },
      size, // Limiter le nombre de résultats
    };
    return this.http.post<any>(url, body, {headers});
  }

  searchUserHistory(query: string | null): Observable<any> {
    const url = `${this.elasticsearchUrl}/${this.searchHistoryDataStream}/_search`;
    const headers = new HttpHeaders({
      'Authorization': `ApiKey ${this.apiKey}`
    });
    const escapedQuery = query ? this.escape(query) : '';
    const user_id = this.msalService.instance.getActiveAccount()?.idTokenClaims?.oid;

    const body = {
      size: 20,
      query: {
        bool: {
          must: [
            {
              query_string: {
                default_field: "search_query",
                query: `*${escapedQuery}*`
              }
            },
            {
              term: {
                user_id: user_id   // Remplacez par l'ID de l'utilisateur connecté
              }
            }
          ]
        }
      },
    };
    return this.http.post<any>(url, body, {headers});
  }

  updatePerPage(perPage: number): void {
    this.perPage.next(perPage);
  }

}
