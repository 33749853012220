@if (breves.length > 0) {
    <div class="breves">
        <h3>En bref</h3>
        <swiper-container *ngIf="swiperDirection" #swiperContainer class="swiper-container"
                          [ngClass]="{'vertical': swiperDirection === 'vertical', 'horizontal': swiperDirection === 'horizontal'}"
                          init="false">
            <ng-container *ngIf="swiperDirection === 'vertical'; else horizontalSlides">
                <swiper-slide *ngFor="let group of groupedBreves" class="swiper-item">
                    <div *ngFor="let breve of group" class="breve">
                        <span class="breve--date">{{breve.date}}</span>
                        <span *ngIf="breve?.dt" class="breve--tag tag bg-darkblue">{{(breve.dt === 'Siège')? breve.dt :'DT ' + breve.dt}}</span>
                        <p class="breve--paragraph"
                           [customInnerHTML]="breve.text.length > 130 ? (breve.text.slice(0, 130) + '...') : breve.text">
                        </p>
                        <ng-container *ngIf="breve.text.length > 130 || breve.lien">
                            <a class="breve--modale-opener" href="#" (click)="openModal($event, breve)">Lire l'intégralité</a>
                        </ng-container>
                    </div>
                </swiper-slide>
            </ng-container>
            <ng-template #horizontalSlides>
                <swiper-slide *ngFor="let breve of breves" class="swiper-item">
                    <div class="breve">
                        <span class="breve--date">{{breve.date}}</span>
                        <span *ngIf="breve?.dt" class="breve--tag tag bg-darkblue">{{(breve.dt === 'Siège')? breve.dt :'DT ' + breve.dt}}</span>
                        <p class="breve--paragraph"
                           [customInnerHTML]="breve.text.length > 130 ? (breve.text.slice(0, 130) + '...') : breve.text">
                        </p>
                        <ng-container *ngIf="breve.text.length > 130 || breve.lien">
                            <a class="breve--modale-opener" href="#" (click)="openModal($event, breve)">Lire l'intégralité</a>
                        </ng-container>
                    </div>
                </swiper-slide>
            </ng-template>
        </swiper-container>
        <ng-container *ngIf="groupedBreves.length === 0">
            <p>Aucune donnée disponible.</p>
        </ng-container>
        <ng-container *ngIf="swiperDirection === 'vertical'; else modaleMobile">
            <div *ngFor="let group of groupedBreves">
                <div *ngFor="let breve of group">
                    <div class="breve--modale modale" [class.show]="breve.isModalOpen" (click)="closeModal($event, breve)">
                        <div class="modale--content" (click)="$event.stopPropagation()">
                            <button class="modale--close" (click)="closeModal($event, breve)" aria-label="Fermer la modale">
                                <span>Fermer la modale</span>
                            </button>
                            <h4 class="modale--date">{{breve.date}}</h4>
                            <div class="modale--paragraph" [customInnerHTML]="breve.richText"></div>
                            <ng-container *ngIf="breve.lien">
                                <a href="{{breve.lien.url}}" target="_blank" class="modale--link btn btn--primary">
                                    {{breve.lien.label || "Lien vers le contenu"}}
                                </a>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-template #modaleMobile>
            <div class="breves-modal">
                <div *ngFor="let breve of breves" class="breves-modal-item">
                    <div class="breve--modale modale" [class.show]="breve.isModalOpen" (click)="closeModal($event, breve)">
                        <div class="modale--content" (click)="$event.stopPropagation()">
                            <span class="modale--close" (click)="closeModal($event, breve)">Fermer la modale</span>
                            <h4 class="modale--date">{{breve.date}}</h4>
                            <div class="modale--paragraph" [customInnerHTML]="breve.richText"></div>
                            <ng-container *ngIf="breve.lien">
                                <a href="{{breve.lien.url}}" target="_blank" class="modale--link btn btn--primary">
                                    {{breve.lien.label || "Lien vers le contenu"}}
                                </a>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </div>
}
