import { NgClass, NgFor } from "@angular/common";
import { Component } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { MsalService } from "@azure/msal-angular";
import { environment } from "../../../environments/environment";
import { AuthService } from "../../services/auth.service";
import { ChangeDetectorRef, HostListener } from '@angular/core';
import { ApiService } from "../../services/api.service";
import { formatServices, useCache } from "../../utils/utils.service";
import { Service } from "../../models/service";
import { SidenavService } from "../../services/sidenav.service";

@Component({
  selector: 'app-sidenav',
  standalone: true,
  imports: [
    NgClass,
    NgFor,
    RouterModule
  ],
  templateUrl: './sidenav.component.html',
  styleUrl: './sidenav.component.scss'
})
export class SidenavComponent {

  // Stockage des états des listes (ouvertes/fermées)
  private listStates: { [key: string]: boolean } = {};

  private currentRoute: string = '';

  protected isOpen = false;

  userServices: Service[] = [];

  constructor(
    private msalService: MsalService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private apiService: ApiService, 
    private sidenavService: SidenavService
  ) {
    // Initialisez les états des listes à 'fermé' (false)
    this.listStates['menuExplorer'] = false;
    this.listStates['menuServices'] = false;
    // Ajoutez d'autres états de liste ici si nécessaire
  }

  ngOnInit(): void {
    // charger l'état sauvegardé par l'utilisateur
    this.isOpen = localStorage.getItem('navbarIsOpen') === 'true' ? true : false;
    this.updateMenuStates();
    // Récupérer le chemin de la route actuelle
    this.currentRoute = this.route.snapshot.url.join('/');
    environment.enableLogging && console.log("Current route: ", this.currentRoute);

    // mise à jour des services de l'utilisateur en fonction de ce qu'il a enregistré dans la page Mes Services
    this.sidenavService.currentServices.subscribe((services) => {
      this.userServices = services;
    });

    useCache('sidenav', this, async () => {
      await Promise.all([
        this.getUserFavorites(),
      ]);
    }, () => {
      return {
        userServices: this.userServices,
      }
    });
  }

  updateMenuStates() {
    for (const [key, value] of Object.entries(this.listStates)) {
      this.listStates[key] = localStorage.getItem(key + 'IsOpen') === 'true' ? true : false;
    }
  }

  // Méthode pour basculer l'état d'une liste
  toggleList(id: string): void {
    this.listStates[id] = !this.listStates[id];
    localStorage.setItem(id + 'IsOpen', JSON.stringify(this.listStates[id]));
  }

  // Méthode pour vérifier si une liste est ouverte
  isListOpen(id: string): boolean {
    return this.listStates[id];
  }

  toggleSidenav() {
    this.isOpen = !this.isOpen;
    localStorage.setItem('navbarIsOpen', JSON.stringify(this.isOpen));
  }

  onLogout() {
    this.authService.triggerLogoutAction();
  }

  // Méthode pour replier le sidenav en mobile
  @HostListener('window:resize')
  closeSidenavOnMobile() {
    if (window.innerWidth <= 768) {
      this.isOpen = false;
      localStorage.setItem('navbarIsOpen', 'false');
      this.cdr.markForCheck();
    }
  }

  // Fermer le sidenav lors du clic sur un lien
  onLinkClick() {
    if (window.innerWidth <= 768) {
      this.isOpen = false;
      localStorage.setItem('navbarIsOpen', 'false');
    }
  }

  async getUserFavorites() {
    const { body, headers } = await this.apiService.getUserFavorites();
    environment.enableLogging && console.log('sidenav', body, headers);

    if (body && body.services) {
      this.userServices = formatServices(body.services);
    }
  }
}
