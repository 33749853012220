<div class="essentiel-hp">
  <h2 class="essentiel-hp__title">L'Essentiel</h2>
  <div class="essentiel-hp__container">
    <div class="essentiel-hp__col-left">

      <div class="essentiel-hp__diamond">
        <div class="essentiel-hp__subtitle">
          <div class="essentiel-hp__animation">
            <ng-lottie [options]="optionsQui" width="100" height="100"
              (animationCreated)="animationCreated('qui', $event)">
            </ng-lottie>
          </div>
          <div class="essentiel-hp__subtitle-container">
            <h3 class="essentiel-hp__subtitle-title">Qui sommes-nous ?</h3>
            <p class="essentiel-hp__subtitle-text" [innerHTML]="chapoIdentite"></p>
          </div>
          <a class="essentiel-hp__subtitle-link" routerLink="/qui-sommes-nous" (mouseenter)="playAnimation('qui')"
            (mouseleave)="playReverseAnimation('qui')">Qui sommes-nous ?</a>
        </div>
        <div class="essentiel-hp__content">

          <div class="list__push-icon slider" *ngIf="quiSommesNousZooms.length > 0">
            <div class="list__push-icon__item">
              <div class="list__push-icon__image">
                <span class="icon vnf-icons-icon-m-information-outline"></span>
              </div>
              <swiper-container class="swiper-container" #swiperEssentialHomeContainer>
                <swiper-slide *ngFor="let zoom of quiSommesNousZooms; let i = index">
                  <div class="list__push-icon__text">
                    <p [innerHTML]="zoom.title"></p>
                  </div>
                  <a href="{{zoom.lien?.url}}" [rel]="zoom.lien?.type === 'ext'? 'external': ''"
                    [target]="zoom.lien?.type === 'ext'? '_blank': ''" class="list__push-icon__item__link">Accéder</a>
                </swiper-slide>
              </swiper-container>
            </div>
          </div>

          <div class="list__card">
            <div class="list__card__item" *ngFor="let entry of quiSommesNousEntries; let i = index">
              <div class="list__card__text">
                <p [innerHTML]="entry.title"></p>
              </div>
              <a class="list__card__item__link" href="{{entry.url}}" [rel]="entry.type === 'ext'? 'external': ''"
                [target]="entry.type === 'ext'? '_blank': ''">Accéder</a>
            </div>
          </div>

          <div class="essentiel-hp__link-container">
            <a routerLink="/qui-sommes-nous" class="essentiel-hp__link btn btn--primary">Découvrir</a>
          </div>
        </div>
      </div>

      <div class="essentiel-hp__triangle">
        <div class="essentiel-hp__subtitle">
          <div class="essentiel-hp__animation">
            <ng-lottie [options]="optionsCap" width="100" height="100"
              (animationCreated)="animationCreated('cap', $event)">
            </ng-lottie>
          </div>
          <div class="essentiel-hp__subtitle-container">
            <h3 class="essentiel-hp__subtitle-title">Quel cap ?</h3>
            <p class="essentiel-hp__subtitle-text"[innerHTML]="chapoCap"></p>
          </div>
          <a class="essentiel-hp__subtitle-link" routerLink="/quel-cap" (mouseenter)="playAnimation('cap')"
            (mouseleave)="playReverseAnimation('cap')">Quel cap ?</a>
        </div>
        <div class="essentiel-hp__content">

          <div class="list__push-icon slider" *ngIf="quelCapZooms.length > 0">
            <div class="list__push-icon__item">
              <div class="list__push-icon__image">
                <span class="icon vnf-icons-icon-m-information-outline"></span>
              </div>
              <swiper-container class="swiper-container" #swiperEssentialHomeContainer>
                <swiper-slide *ngFor="let zoom of quelCapZooms; let i = index">
                  <div class="list__push-icon__text">
                    <p [innerHTML]="zoom.title"></p>
                  </div>
                  <a href="{{zoom.lien?.url}}" [rel]="zoom.lien?.type === 'ext'? 'external': ''"
                    [target]="zoom.lien?.type === 'ext'? '_blank': ''" class="list__push-icon__item__link">Accéder</a>
                </swiper-slide>
              </swiper-container>
            </div>
          </div>

          <div class="list__card">
            <div class="list__card__item" *ngFor="let entry of quelCapEntries; let i = index">
              <div class="list__card__text">
                <p [innerHTML]="entry.title"></p>
              </div>
              <a class="list__card__item__link" href="{{entry.url}}" [rel]="entry.type === 'ext'? 'external': ''"
                [target]="entry.type === 'ext'? '_blank': ''">Accéder</a>
            </div>
          </div>
          <div class="essentiel-hp__link-container">
            <a routerLink="/quel-cap" class="essentiel-hp__link btn btn--primary">Découvrir</a>
          </div>
        </div>
      </div>
    </div>

    <div class="essentiel-hp__col-right">
      <div class="essentiel-hp__circle">
        <div class="essentiel-hp__subtitle">
          <div class="essentiel-hp__animation">
            <ng-lottie [options]="optionsQuotidien" width="100" height="100"
              (animationCreated)="animationCreated('quotidien', $event)">
            </ng-lottie>
          </div>
          <div class="essentiel-hp__subtitle-container">
            <h3 class="essentiel-hp__subtitle-title">Au quotidien ?</h3>
            <p class="essentiel-hp__subtitle-text" [innerHTML]="chapoQuotidien"></p>
          </div>
          <a class="essentiel-hp__subtitle-link" routerLink="/au-quotidien" (mouseenter)="playAnimation('quotidien')"
            (mouseleave)="playReverseAnimation('quotidien')">Au quotidien ?</a>
        </div>
      </div>

      <div class="essentiel-hp__content">

        <div class="list__push-icon slider" *ngIf="auQuotidienZooms.length > 0">
          <div class="list__push-icon__item">
            <div class="list__push-icon__image">
              <span class="icon vnf-icons-icon-m-information-outline"></span>
            </div>
            <swiper-container class="swiper-container" #swiperEssentialHomeContainer>
              <swiper-slide *ngFor="let zoom of auQuotidienZooms; let i = index">
                <div class="list__push-icon__text">
                  <p [innerHTML]="zoom.title"></p>
                </div>
                <a href="{{zoom.lien?.url}}" [rel]="zoom.lien?.type === 'ext'? 'external': ''"
                  [target]="zoom.lien?.type === 'ext'? '_blank': ''" class="list__push-icon__item__link">Accéder</a>
              </swiper-slide>
            </swiper-container>
          </div>
        </div>


        <div class="list__card">
          <div class="list__card__item" *ngFor="let entry of auQuotidienEntries; let i = index">
            <div class="list__card__text">
              <p [innerHTML]="entry.title"></p>
            </div>
            <a class="list__card__item__link" href="{{entry.url}}" [rel]="entry.type === 'ext'? 'external': ''"
              [target]="entry.type === 'ext'? '_blank': ''">Accéder</a>
          </div>
        </div>

        @for (auQuotidienTag of auQuotidienTags; track auQuotidienTag.id) {
          <h4 class="tag_list__title" [innerHTML]="auQuotidienTag.title"></h4>

          <div class="tag_list">
            <span class="tag bg-white" *ngFor="let entree of auQuotidienTag.entrees; let i = index" [innerHTML]="entree"></span>
          </div>

          <div class="card__item" *ngIf="auQuotidienTag.cardComplementaire">
            <div class="card__item__image">
              <picture>
                <source width="181" height="130" media="(min-width: 768px)" srcset="{{auQuotidienTag.cardComplementaire.visuel.sizes?.['w182h130']}}">
                <source width="330" height="130" media="(max-width: 767px)" srcset="{{auQuotidienTag.cardComplementaire.visuel.sizes?.['w330h130']}}">
                <img width="181" height="130" src="{{auQuotidienTag.cardComplementaire.visuel.sizes?.['w182h130'] || auQuotidienTag.cardComplementaire.visuel.url}}" alt="{{auQuotidienTag.cardComplementaire.visuel.alt}}">
              </picture>
            </div>
            <div class="card__item__content">
              <h3 class="card__item__title">Le lab est à votre disposition</h3>
              <p class="card__item__text">Un espace moderne et convivial pour partager et construire ensemble !</p>
              <a class="list__card__item__link" href="https://www.vnf.fr/vnf/">Accéder</a>
            </div>
          </div>
        }

        <div class="essentiel-hp__link-container"><a routerLink="/au-quotidien"
            class="essentiel-hp__link btn btn--primary">Découvrir</a>
        </div>
      </div>
    </div>
  </div>
</div>