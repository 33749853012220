import { Link } from "./link";
import { Tool } from "./tool";
import { Visuel } from "./visuel";

export interface Flexible {
    id?: number;
    bColor?: 'gris' | 'blanc';
    type: 'img' | 'highlight-card' | 'flash-info' |
    'video' | 'push-page' | 'onglet' | 'onglets' |
    'text' | 'text-img' | 'arche' | 'dossiers' |
    'button' | 'data-visualisation' | 'slider-img' |
    'separateur' | 'spacer' | 'push-services' | 'push-medias' |
    'push-medias-title' | 'reporting' | 'push-reportings' |
    'profil-card' | 'push-tools' | 'list-links' |
    'accordions' | 'form-contact' | 'push-portraits' | 'similar-articles';
}

export interface FlashInfo extends Flexible {
    info: string;
    typeLien: 'int' | 'ext';
    lien: string;
}
export interface FlexibleText extends Flexible {
    content: string;
}

export interface FlexibleImg extends Flexible {
    visuel: Visuel;
    legend?: string;
}

export interface FlexibleTextImg extends Flexible {
    title: string;
    content: string;
    visuel: Visuel;
}

export interface FlexibleVideo extends Flexible {
    url: string;
    visuel?: Visuel;
}

export interface FlexibleHighlightCard extends Flexible {
    content: string;
    url: string;
    full_width: boolean;
}

export interface FlexibleArche extends Flexible {
    title: string;
    aColor: 'bleu_clair' | 'bleu_fonce' | 'blanc';
    full_width: boolean;
}

export interface FlexibleButton extends Flexible {
    title: string;
    url: string;
    bType: 'button_secondary_m_outline' | 'button_secondary_xl';
}

export interface FlexibleDataVisualisation extends Flexible {
    title: string;
    bTitle: string;
    bUrl: string;
    elements: {
        id: number;
        style: 'style-1' | 'style-2' | 'style-3';
        title: string;
        url: string;
        line1: string;
        line2: string;
        line3: string;
        visuel: Visuel;
        visuel_texte: string;
    }[];
}

export interface FlexibleSliderImg extends Flexible {
    visuels: Visuel[];
}

export interface FlexibleOnglets extends Flexible {
    onglets: {
        id: number;
        title: string;
        flexibles: Flexible[];
    }[];
}


export interface FlexibleEntry {
    id: number;
    title: string;
    flexibles: Flexible[];
}

export interface FlexibleSeparateur extends Flexible { }

export interface FlexibleSpacer extends Flexible {
    size: 'm' | 'xl';
}

export interface FlexiblePushServices extends Flexible {
    services: {
        id: number;
        title: string;
        description?: string;
        lien: Link;
        fontPicto?: string;
    }[];
}

export interface FlexiblePushMedias extends Flexible {
    medias: {
        id: number;
        cColor: 'bleu-fonce' | 'vert' | 'bleu';
        typeMedia: 'img' | 'video';
        lienVideo?: Link;
        visuel: Visuel;
    }[];
}

export interface FlexiblePushMediasTitle extends Flexible {
    medias: {
        id: number;
        title: string;
        cColor: 'bleu-fonce' | 'vert' | 'bleu';
        typeMedia: 'img' | 'video';
        lienVideo?: Link;
        visuel: Visuel;
    }[];
}

export interface FlexibleReporting extends Flexible {
    visuel: Visuel;
    title: string;
    content: string;
}

export interface FlexiblePushReportings extends Flexible {
    title: string;
    reportings: {
        id: number;
        title: string;
        content: string;
        thematique: string;
        visuel: Visuel;
        lien: Link;
    }[];
}

export interface FlexiblePushPage extends Flexible {
    elements: {
        id: number;
        type: 'page' | 'document';
        title: string;
        lien: Link;
        visuel?: Visuel,
        tagMain?: string;
        tagSecondary?: string;
    }[];
}

export interface FlexibleProfilCard extends Flexible {
    title: string;
    lien: Link;
    profils: {
        id: number;
        name: string;
        fonction?: string;
        phone?: string;
        email?: string;
        visuel?: Visuel;
    }[];
}

export interface FlexiblePushTools extends Flexible {
    tools: Tool[];
}

export interface FlexiblePanel extends Flexible {
    panel: {
        id: number;
        title: string;
        content: string;
    }[];
}

export interface FlexibleFormContact extends Flexible {
    title: string;
    label: string;
    placeholder: string;
}

export interface FlexiblePushPortraits extends Flexible {
    portraits: {
        id: number;
        fonction: string;
        nom: string;
        content: string;
        visuel: Visuel;
    }[];
}

export interface FlexibleListLinks extends Flexible {
    links: Link[];
}

export interface FlexibleAccordions extends Flexible {
    accordions: {
        id: number;
        title: string;
        flexibles: Flexible[];
    }[];
}

export interface FlexibleSimilarArticles extends Flexible {
    articles: {
        id: number;
        title: string;
        date: {
            format_day: string;
            format_month: string;
            format_year: string;
            full: string;
        };
        description: string;
        visuel: Visuel;
        perimetre: string;
        theme: string;
        lien: Link;
    }[];
    medias: {
        id: number;
        title: string;
        visuel: Visuel;
        lien?: Link;
        perimetre: string;
        theme: string;
        video?: string;
        infographie?: Visuel;
    }[];
}

export interface FlexibleDossiers extends Flexible {
    title: string;
    dossiers: {
        id: number;
        title: string;
        content: string;
        thematique: string;
        visuel: Visuel;
        lien: Link;
    }[];
}

export function isFlexibleText(flexible: Flexible): flexible is FlexibleText {
    return flexible.type === 'text';
}

export function isFlexibleImg(flexible: Flexible): flexible is FlexibleImg {
    return flexible.type === 'img';
}

export function isFlexibleTextImg(flexible: Flexible): flexible is FlexibleTextImg {
    return flexible.type === 'text-img';
}

export function isFlexibleFlashInfo(flexible: Flexible): flexible is FlashInfo {
    return flexible.type === 'flash-info';
}

export function isFlexibleOnglets(flexible: Flexible): flexible is FlexibleOnglets {
    return flexible.type === 'onglets';
}

export function isFlexibleVideo(flexible: Flexible): flexible is FlexibleVideo {
    return flexible.type === 'video';
}

export function isFlexibleHighlightCard(flexible: Flexible): flexible is FlexibleHighlightCard {
    return flexible.type === 'highlight-card';
}

export function isFlexibleArche(flexible: Flexible): flexible is FlexibleArche {
    return flexible.type === 'arche';
}

export function isFlexibleButton(flexible: Flexible): flexible is FlexibleButton {
    return flexible.type === 'button';
}

export function isFlexibleDataVisualisation(flexible: Flexible): flexible is FlexibleDataVisualisation {
    return flexible.type === 'data-visualisation';
}

export function isFlexibleSliderImg(flexible: Flexible): flexible is FlexibleSliderImg {
    return flexible.type === 'slider-img';
}

export function isFlexibleSeparateur(flexible: Flexible): flexible is FlexibleSeparateur {
    return flexible.type === 'separateur';
}

export function isFlexibleSpacer(flexible: Flexible): flexible is FlexibleSpacer {
    return flexible.type === 'spacer';
}

export function isFlexiblePushServices(flexible: Flexible): flexible is FlexiblePushServices {
    return flexible.type === 'push-services';
}

export function isFlexiblePushMedias(flexible: Flexible): flexible is FlexiblePushMedias {
    return flexible.type === 'push-medias';
}

export function isFlexiblePushMediasTitle(flexible: Flexible): flexible is FlexiblePushMediasTitle {
    return flexible.type === 'push-medias-title';
}

export function isFlexiblePushReportings(flexible: Flexible): flexible is FlexiblePushReportings {
    return flexible.type === 'push-reportings';
}

export function isFlexibleReporting(flexible: Flexible): flexible is FlexibleReporting {
    return flexible.type === 'reporting';
}

export function isFlexiblePushPage(flexible: Flexible): flexible is FlexiblePushPage {
    return flexible.type === 'push-page';
}

export function isFlexibleProfilCard(flexible: Flexible): flexible is FlexibleProfilCard {
    return flexible.type === 'profil-card';
}

export function isFlexiblePushTools(flexible: Flexible): flexible is FlexiblePushTools {
    return flexible.type === 'push-tools';
}

export function isFlexibleListLinks(flexible: Flexible): flexible is FlexibleListLinks {
    return flexible.type === 'list-links';
}

export function isFlexibleAccordions(flexible: Flexible): flexible is FlexibleAccordions {
    return flexible.type === 'accordions';
}

export function isFlexibleFormContact(flexible: Flexible): flexible is FlexibleFormContact {
    return flexible.type === 'form-contact';
}

export function isFlexiblePushPortraits(flexible: Flexible): flexible is FlexiblePushPortraits {
    return flexible.type === 'push-portraits';
}

export function isFlexibleSimilarArticles(flexible: Flexible): flexible is FlexibleSimilarArticles {
    return flexible.type === 'similar-articles';
}

export function isFlexibleDossiers(flexible: Flexible): flexible is FlexibleDossiers {
    return flexible.type === 'dossiers';
}
