import {Component, ElementRef, HostListener, inject, Input, ViewChild} from '@angular/core';
import {copyUrlToClipboard} from "../../../utils/utils.service";
import {DatePipe, NgIf, UpperCasePipe} from "@angular/common";
import {DialogSendEmailComponent} from "../../dialog/dialog-send-email/dialog-send-email.component";
import {MatDialog} from "@angular/material/dialog";
import {DialogDocumentInfoComponent} from "../../dialog/dialog-document-info/dialog-document-info.component";

@Component({
  selector: 'app-search-document',
  standalone: true,
  imports: [
    DatePipe,
    NgIf,
    UpperCasePipe
  ],
  templateUrl: './search-document.component.html',
  styleUrl: './search-document.component.scss'
})
export class SearchDocumentComponent {
  @Input('document') document!: any;
  @ViewChild('optionsButton') optionsButton!: ElementRef;
  @ViewChild('options') options!: ElementRef;
  copyUrlToClipboard = copyUrlToClipboard;
  readonly dialog = inject(MatDialog);
  isOptionsActive = false;
  static anyOptionsActive: boolean = false;

  toggleOptions(): void {
    this.isOptionsActive = !this.isOptionsActive;
    SearchDocumentComponent.anyOptionsActive = this.isOptionsActive;
  }

  // Ferme les options si on clique en dehors des options et du bouton
  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent): void {
    const targetElement = event.target as HTMLElement;

    if (
      this.optionsButton &&
      this.isOptionsActive &&
      !this.optionsButton.nativeElement.contains(targetElement) &&
      !this.options.nativeElement.contains(targetElement) &&
      SearchDocumentComponent.anyOptionsActive
    ) {
      setTimeout(() => {
        this.isOptionsActive = false;
        SearchDocumentComponent.anyOptionsActive = false;
      }, 0);
    }
  }

  openDocumentUrl(event: MouseEvent): void {
    const targetElement = event.target as HTMLElement;
    if (!this.optionsButton.nativeElement.contains(targetElement) && !this.options.nativeElement.contains(targetElement) && !SearchDocumentComponent.anyOptionsActive) {
      window.open(this.document.webUrl, '_blank');
    }
  }

  openDialogMedia() {
    const document = this.document;
    this.dialog.open(DialogDocumentInfoComponent, {
      width: '350px',
      data: {document},
    });
  }

  openDialogShareByEmail(): void {
    const document = this.document;
    const docType = document.nature_du_document;
    const docPerimetre = document.perimetre;
    this.dialog.open(DialogSendEmailComponent, {
      width: '100%',
      maxWidth: '740px',
      data: {
        docTitle: document.name,
        docUrl: document.webUrl,
        docType: docType,
        docPerimetre: docPerimetre,
        docDate: document.date_d_effet
      },
    });
  }

  isPointerActive(): boolean
  {
   return !SearchDocumentComponent.anyOptionsActive;
  }
}
