<div *ngIf="flexible" [ngClass]="{'bg-white': flexible.bColor === 'blanc'}">
    <div class="flexible-img flexible-content">
            @if (flexible.legend) {
                <figure>
                    <picture>
                        <source srcset="{{flexible.visuel.sizes?.['w885h397']}}" width="885" height="397" media="(min-width: 992px)" />
                        <source srcset="{{flexible.visuel.sizes?.['w740h332']}}" width="740" height="332" media="(min-width: 768px)" />
                        <source srcset="{{flexible.visuel.sizes?.['w360h190']}}" width="360" height="190" media="(max-width: 420px)" />
                        <img src="{{flexible.visuel.sizes?.['w885h397']}}" width="885" height="397"
                             [alt]="flexible.visuel.alt || 'Highlight article image'" />
                    </picture>
                    <figcaption *ngIf="flexible.legend">
                        {{flexible.legend}}
                    </figcaption>
                </figure>
            } @else {
                <picture>
                    <source srcset="{{flexible.visuel.sizes?.['w885h397']}}" width="885" height="397" media="(min-width: 992px)" />
                    <source srcset="{{flexible.visuel.sizes?.['w740h332']}}" width="740" height="332" media="(min-width: 768px)" />
                    <source srcset="{{flexible.visuel.sizes?.['w360h190']}}" width="360" height="190" media="(max-width: 420px)" />
                    <img src="{{flexible.visuel.sizes?.['w885h397']}}" width="885" height="397"
                         [alt]="flexible.visuel.alt || 'Highlight article image'" />
                </picture>
            }
    </div>
</div>
