import { NgFor } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-pagination',
  standalone: true,
  imports: [NgFor],
  templateUrl: './pagination.component.html',
  styleUrl: './pagination.component.scss'
})
export class PaginationComponent {
  @Input() currentPage: number = 1;
  @Input() totalPages: number = 1;
  @Output() pageChanged = new EventEmitter<number>();
  @Output() limitChanged = new EventEmitter<number>();
  @Input() isNbElementsChangeable: boolean = false;
  @Input() listLimit: number[] = [10, 20, 30];
  isOptionsActive: boolean = false;

  @ViewChild('optionsOpener', { static: false }) optionsOpener!: ElementRef;
  @ViewChild('optionsMenu', { static: false }) optionsMenu!: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {
  }

  goToPage(p: number): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { p: p },
      queryParamsHandling: 'merge'
    });
    this.pageChanged.emit(p);
  }

  editLimit(l: number): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { limit: l },
      queryParamsHandling: 'merge'
    });
    this.limitChanged.emit(l);
  }

  toggleOptions(): void {
    this.isOptionsActive = !this.isOptionsActive;
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent): void {
    if (this.optionsOpener && this.optionsMenu) {
      const targetElement = event.target as HTMLElement;
      const clickedInsideOpener = this.optionsOpener.nativeElement.contains(targetElement);
      const clickedInsideMenu = this.optionsMenu.nativeElement.contains(targetElement);

      if (!clickedInsideOpener && !clickedInsideMenu) {
        this.isOptionsActive = false;
      }
    }
  }
}
