<div class="news-feed__item {{actualite.important? 'important' : ''}}"
     *ngFor="let actualite of actualites; let i = index">
    <div class="news-feed__img">
        <div class="news-feed__date">
            <time>
                <span class="news-feed__day">{{actualite.date.format_day}}</span>
                    <span class="news-feed__year">{{actualite.date.format_year}}</span>
            </time>
        </div>
        <img src="{{actualite.visuel.url}}" alt="{{actualite.visuel.alt}}">
    </div>
    <div class="news-feed__content">

        <button class="options-opener news-feed__options--opener" (click)="toggleFeedOptions(i)">Activer les
            options</button>

        <div class="options news-feed__options" [class.active]="isFeedItemOptionsActive[i]">
            <ul class="options_list">
                <li class="options_list--item"><a (click)="openDialogShareByEmail(actualite.lien.url)">Envoyer par mail</a></li>
                <li class="options_list--item"><a (click)="copyUrlToClipboard(actualite.lien.url)">Copier le lien</a></li>
                <li class="options_list--item"><a href="{{ '/' + actualite.lien.url}}">En savoir plus</a></li>
            </ul>
        </div>

        <h4 class="news-feed__title" [innerHTML]="actualite.title"></h4>
        @if(actualite.important) {
        <span class="news-feed__important">Important</span>
        }

        <div class="tag_list">
            <span class="tag bg-darkblue" *ngIf="actualite.dt"  [innerHTML]="'DT ' + actualite.dt"></span>
            <span class="tag bg-darkblue" *ngIf="actualite.perimetre === 'Siège'"  [innerHTML]="'Siège'"></span>
            <span class="tag bg-blue" [innerHTML]="actualite.theme"></span>
        </div>
        <p class="news-feed__text" [innerHTML]="actualite.chapo"></p>
    </div>
</div>
