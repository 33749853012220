import {Component, Input} from '@angular/core';
import {DatePipe, NgForOf, NgIf, UpperCasePipe} from "@angular/common";
import {SearchPaginationComponent} from "../search-pagination/search-pagination.component";
import {SearchDocumentComponent} from "../search-document/search-document.component";

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  standalone: true,
  imports: [
    NgForOf,
    DatePipe,
    SearchPaginationComponent,
    NgIf,
    UpperCasePipe,
    SearchDocumentComponent
  ],
  styleUrls: ['./search-results.component.scss']
})
export class SearchResultsComponent {
  @Input('documents') documents: any[] = [];
}
