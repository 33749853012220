// configuration d'intégration (Azure)
export const environment = {
  production: false,
  enableLogging: true,
  cacheExpiration: 3600, //seconds
  apiUrl: 'https://vnfetmoi-api-int2.vnf.fr/wp-json',
  msalConfig: {
    // Configuration Azure VNF INT
    auth: {
      clientId: 'a35c2f97-b6e6-4450-b518-5a4a5478215a',
      authority: 'https://login.microsoftonline.com/e656fd6a-aaca-470b-a737-8d19eb341c97/v2.0',
    },
  },
  azureApiConfig: {
    scopes: ['user.read', 'profile', 'openid', 'Calendars.readWrite'],
    uri: 'https://graph.microsoft.com/v1.0/me',
  },
  elasticSearchConfig: {
    elasticsearchUrl: 'https://vnf-data-prep.es.francecentral.azure.elastic-cloud.com:443',
    apiKey: 'WW1wYkM1TUJNMWJnajdiNXZabEY6Q3RnZ3NwUmpTeC00c1dVbFRBdGJXdw==',
    searchIndex: 'vnf-data-ged-sharepoint-prep',
    searchHistoryDataStream: 'vnf-data-app-historic-vnfetmoi-prep'
  }
};