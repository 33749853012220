import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidenavService {

  constructor() { }

  private servicesSource = new BehaviorSubject<any>(null);
  currentServices = this.servicesSource.asObservable();

  changeServices(data: any) {
    this.servicesSource.next(data);
  }
}
