import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common'; // Ajout de CommonModule
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { FlexiblePushMedias } from '../../../models/flexibles';
import { Visuel } from '../../../models/visuel';
import { DialogTextImgVideoComponent } from '../../dialog/dialog-text-img-video/dialog-text-img-video.component';
import { Link } from '../../../models/link'; // Add this import

@Component({
  selector: 'app-flexible-push-medias',
  standalone: true,
  imports: [MatButtonModule, MatDialogModule, CommonModule],
  templateUrl: './flexible-push-medias.component.html',
  styleUrl: './flexible-push-medias.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlexiblePushMediasComponent {
  @Input() flexible!: FlexiblePushMedias;
  readonly dialog = inject(MatDialog);

  openDialogMedia(media: { visuel: Visuel, lienVideo?: Link }) {
    const dialogRef = this.dialog.open(DialogTextImgVideoComponent, {
      width: '1100px',
      height: '700px',
      maxWidth: '100vw',
      maxHeight: '100vh',
      data: {
        ...(media.lienVideo && { flexibleVideo: { url: media.lienVideo, visuel: media.visuel } }),
        ...(!media.lienVideo && { visuel: media.visuel }),
      }
    });
  }
}
