import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogClose, MatDialogContent} from "@angular/material/dialog";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-dialog-document-info',
  standalone: true,
  imports: [
    MatDialogClose,
    MatDialogContent,
    DatePipe
  ],
  templateUrl: './dialog-document-info.component.html',
  styleUrl: './dialog-document-info.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogDocumentInfoComponent {
  document!: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.document = data.document;
  }

}
