<div class="main-layout">
  <app-loading-overlay [isLoading]="isLoading"></app-loading-overlay>
  <div class="layout">

    <div class="col-fullwidth">
      <div class="news-headline-hp back-button">
        <a class="back-button" routerLink="/">Revenir à l'accueil</a>
        <h2 class="title-icons-underline"><span class="icon vnf-icons-icon-news"></span>Actualités</h2>
      </div>
    </div>

    <div class="col-fullwidth mb-15">
      <app-filters-bar
              [filtres]="filtres"
              (filtresChange)="onFiltresChange($event)"
              (searchTriggerred)="onSearch()"
      >
      </app-filters-bar>
    </div>

    <div class="cols-container mb-15">
      <div class="col-half">
        <app-participation [appelsParticipations]="appelsParticipations"></app-participation>
      </div>
      <div class="col-half">
        <app-events [events]="events"></app-events>
      </div>
    </div>

    <div class="col-fullwidth mb-30" *ngIf="enImages && enImages.length">
      <app-en-images [enImages]="enImages"></app-en-images>
    </div>

    <div class="col-left mb-60">

      <div class="news-headline">

        <h3>À la une</h3>

        <div class="news-headline__list">
          <app-actualites-a-la-une class="tools_list--item-wrapper"
            [actualites]="actualitesALaUne"></app-actualites-a-la-une>
        </div>
      </div>

      <div class="news-feed">
        <h3>Fil d'actu</h3>
        <div class="news-feed__list">
          <app-list-actualites class="tools_list--item-wrapper" [actualites]="actualites"></app-list-actualites>
        </div>
      </div>
    </div>

    <div class="col-right mb-60">
      <app-breves [breves]="breves"></app-breves>
    </div>

  </div>
  <app-pagination [currentPage]="currentPage" [totalPages]="totalPages" (pageChanged)="onPageChanged($event)"
    [isNbElementsChangeable]="true" (limitChanged)="onLimitChanged($event)"></app-pagination>
</div>
