<div class="mat-mdc-dialog-header">
    <button mat-dialog-close cdkFocusInitial class="mat-mdc-dialog-close-button">Fermer</button></div>

  <app-loading-overlay [isLoading]="isLoading"></app-loading-overlay>

  <mat-dialog-content class="mat-typography">
    <p class="modale-email-title">À qui souhaitez-vous transmettre cet e-mail ?</p>

      <mat-form-field appearance="outline">
        <mat-label>Adresse e-mail</mat-label>
        <input matInput type="email" placeholder="exemple@vnf.fr" required #emailInput>

      </mat-form-field>
    <div *ngIf="errorMessage" class="error-message">
      {{ errorMessage }}
    </div>

  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <a (click)="sendEmail(emailInput.value)" class="btn btn--secondary">Envoyer</a>

  </mat-dialog-actions>
