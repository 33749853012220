<div class="main-layout">
  <app-loading-overlay [isLoading]="isLoading"></app-loading-overlay>
  <div class="child-page__actions">
    <button class="previous-page" (click)="back()">Page précédente</button>
    <app-share [options]="shareOptions"></app-share>
  </div>
  <div class="article" [ngClass]="{'important': article?.important}">
    <div class="article__heading">
      <div class="article__title">
        <div class="article__date">
          <time datetime="2024-04-08">
            <span class="article__day" [innerHTML]="article?.date?.format_day"></span>
            <span class="article__month" [innerHTML]="article?.date?.format_month"></span>
            <span class="article__year" [innerHTML]="article?.date?.format_year"></span>
          </time>
        </div>
        <h1 [innerHTML]="article?.title"></h1>
      </div>

      <div class="tag_list">
          <span class="tag bg-darkblue" *ngIf="article?.dt" [innerHTML]="'DT '+article?.dt"></span>
          <span class="tag bg-darkblue" *ngIf="article?.perimetre === 'Siège'"  [innerHTML]="article?.perimetre"></span>
          <span class="tag bg-blue" *ngIf="article?.theme" [innerHTML]="article?.theme"></span>
      </div>

      <span *ngIf="article?.important" class="article__important">
        Important
      </span>
    </div>

    <div class="article__body">
      <div class="article__img">
        <img src="{{article?.visuel?.url}}" alt="{{article?.visuel?.alt}}">
      </div>
      <p class="article__text" [innerHTML]="article?.chapo"></p>
    </div>



    <app-flexibles [flexibles]="article?.flexibles || []"></app-flexibles>
    <app-flexible-similar-articles *ngIf="article?.articleSimilaires" [flexible]="article?.articleSimilaires!"></app-flexible-similar-articles>
  </div>
</div>
