<div class="mat-mdc-dialog-header">
    <p class="document-dialog__title">{{ document.titre }}</p>
    <button mat-dialog-close cdkFocusInitial class="mat-mdc-dialog-close-button">Fermer</button>
</div>

<mat-dialog-content>

    <div class="document-dialog">
        <p class="document-dialog__label">Collaborateur</p>
        <p class="document-dialog__info">{{ document.createdBy.user.displayName }}</p>
        <p class="document-dialog__label">Date d'effet</p>
        <p class="document-dialog__info">{{ document.date_d_effet|date: 'dd/MM/y' }}</p>
        <p class="document-dialog__label">Période de mise à disposition</p>
        <!-- <p class="document-dialog__info">Disponible jusqu'au 18.06.24</p> -->
        <p class="document-dialog__info">{{ 'Disponible pendant ' + document.duree_validite }}</p>
        <p class="document-dialog__label">Périmètre</p>
        <p class="document-dialog__info">{{ document.perimetre }}</p>
        <p class="document-dialog__label">Type de document</p>
        <p class="document-dialog__info">{{ document.nature_du_document }}</p>
    </div>

</mat-dialog-content>
