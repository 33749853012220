<div *ngIf="flexible" [ngClass]="{'bg-white': flexible.bColor === 'blanc'}">
    <ul class="flexible_list_links flexible-content">

        @for(link of flexible.links; track link.id) {
            <li class="flexible_list_links_item">
                <a href="{{link.url}}" class="flexible_list_links_link" rel="{{link.type == 'ext'? 'external' : ''}}"
                [attr.target]="link.type === 'ext' ? '_blank' : '_self'">
                    <span class="flexible_list_links_text1" [customInnerHTML]="link.label"></span>
                    <span class="flexible_list_links_text2" [customInnerHTML]="link.category"></span>
                </a>
            </li>
        }
    </ul>
</div>
