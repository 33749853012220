import {Component, inject} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatDialog} from "@angular/material/dialog";
import {SearchbarComponent} from "../../components/search/searchbar/searchbar.component";
import {SearchResultsComponent} from "../../components/search/search-results/search-results.component";
import {FiltersBarComponent} from "../../components/search/filters-bar/filters-bar.component";
import {SearchResult} from "../../models/search-result";
import {ElasticsearchService} from "../../services/elasticsearch.service";
import {SearchPaginationComponent} from "../../components/search/search-pagination/search-pagination.component";
import {SearchFilter, SearchSubFilter} from "../../models/search-filters";

@Component({
  selector: 'app-recherche',
  standalone: true,
  imports: [CommonModule, SearchbarComponent, SearchResultsComponent, FiltersBarComponent, SearchPaginationComponent],
  templateUrl: './recherche.component.html',
  styleUrls: ['./recherche.component.scss']
})
export class RechercheComponent {
  readonly dialog = inject(MatDialog);
  currentPage: number = 1;
  perPage: number = 5;
  searchQuery: string = '';
  results: any[] = [];
  totalResults: number = 0;

  filtres: {
    perimetres: SearchFilter[] | null,
    themes: SearchFilter[] | null,
    natures: SearchFilter[] | null,
    startDate: Date | null,
    endDate: Date | null
  } = {themes: null, perimetres: [], natures: [], startDate: null, endDate: null};

  readonly natureFiltersNomenclature: {[key: string]: string[]} = {
    "Documents officiels et/ou signés": ["note", "décret", "circulaire", "instruction", "procès verbal", "politique", "procédure", "décision", "délégation", "bulletin officiel", "texte officiel"],
    "Documents internes sans obligation de signature": ["glossaire", "organigramme", "guide", "fiche pratique", "flyer", "modèle-charte", "compte-rendu", "information"],
    "Autre": []
  };

  constructor(private elasticSearchService: ElasticsearchService) {
  }

  ngOnInit(): void {

    // Récupère la requête de recherche depuis l'URL si elle existe
    this.checkQueryUrl();
    if (this.searchQuery) {
      this.search();
    }

    // Souscrire aux changements des résultats de recherche
    this.elasticSearchService.searchResults$.subscribe((searchResult: SearchResult) => {

      // Mets à jour les résultats de recherche
      this.results = searchResult.hits;
      this.totalResults = searchResult.total;

      // Mets à jour les filtres de nature seulement si aucun filtre n'est appliqué
      if (!searchResult.hasFilters && searchResult.facets?.natureDistribution) {
        // Initialisation des filtres principaux avec une valeur de 0 et un tableau vide pour les sous-filtres
        this.filtres.natures = Object.keys(this.natureFiltersNomenclature).map((categorie) => ({
          name: categorie,
          value: "0",
          checked: false,
          active: false,
          sub_filters: []
        }));

        searchResult.facets.natureDistribution
          .filter((bucket: any) => bucket.key !== '')  // On ignore les clés vides
          .forEach((bucket: any) => {
            const { key, doc_count } = bucket;

            // Vérifie si le filtre appartient à l'une des deux catégories
            const category = Object.keys(this.natureFiltersNomenclature).find(
              category => this.natureFiltersNomenclature[category].includes(key.toLowerCase())
            );

            if (category) {
              // Trouve la catégorie correspondante dans `this.filtres.natures` puis ajoute le sous-filtre
              const filtre = this.filtres.natures?.find((f) => f.name === category);
              if (filtre) {
                filtre.sub_filters.push({ name: key, value: doc_count, checked: false, active: false });
              }
            } else {
              // Si le filtre ne correspond à aucune catégorie, on l'ajoute dans "Autres"
              const otherFilter = this.filtres.natures?.find((f) => f.name === "Autres");
              if (otherFilter) {
                otherFilter.sub_filters.push({ name: key, value: doc_count, checked: false, active: false });
              }
            }
          });

        // Filtre final pour supprimer les catégories vides
        this.filtres.natures = this.filtres.natures.filter(
          (filtre) => filtre.sub_filters.length > 0
        );
      }

      // Mets à jour les filtres de périmètre seulement si aucun filtre n'est appliqué
      if (!searchResult.hasFilters && searchResult.facets?.perimetreDistribution) {
        this.filtres.perimetres = searchResult.facets?.perimetreDistribution
          .filter((bucket: any) => bucket.key !== '')
          .map((bucket: any) => {
            return {
              name: bucket.key,
              value: bucket.doc_count,
              checked: false,
              sous_filtres: []
            }
          });
      }
    });
  }

  uncheckedAllFilters() {
    this.filtres.themes?.forEach((filter: SearchFilter) => filter.checked = false);
    this.filtres.natures?.forEach((filter: SearchFilter) => filter.checked = false);
    this.filtres.natures?.forEach((filter: SearchFilter) => filter.sub_filters?.forEach((subFilter: SearchSubFilter) => subFilter.checked = false));
    this.filtres.perimetres?.forEach((filter: SearchFilter) => filter.checked = false);
    this.filtres.perimetres?.forEach((filter: SearchFilter) => filter.sub_filters?.forEach((subFilter: SearchSubFilter) => subFilter.checked = false));
  }

  checkQueryUrl(): void {
    const urlParams = new URLSearchParams(window.location.search);
    const searchQuery = urlParams.get('q');
    if (searchQuery) {
      this.searchQuery = decodeURIComponent(searchQuery) ?? '';
    }
  }

  onPaginationChange(event: any): void {
    // Mettre à jour les infos de pagination
    if (this.perPage !== event.perPage) {
      this.perPage = event.perPage;
      this.currentPage = 1;
    } else {
      this.currentPage = event.page;
    }
    // Lancer une nouvelle recherche
    this.search();
  }

  onSearchTrigerred(query: string): void {
    this.uncheckedAllFilters();
    this.searchQuery = query;
    this.search();
  }

  search(): void {
    this.elasticSearchService.saveNewHistorySearch(this.searchQuery).subscribe();
    this.elasticSearchService.queryDocuments(this.searchQuery, this.perPage, this.currentPage, this.filtres).subscribe();
  }


}
