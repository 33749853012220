import { CommonModule, NgClass, NgFor } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  HostListener,
  inject,
  Input,
  QueryList,
  ViewChildren
} from '@angular/core';
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialog, MatDialogModule } from "@angular/material/dialog";
import { MatFormField, MatLabel } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { MatTab, MatTabGroup } from "@angular/material/tabs";
import { RouterModule } from '@angular/router';

// @ts-ignore
@Component({
  selector: 'app-personal-list',
  standalone: true,
  imports: [
    NgClass,
    NgFor,
    RouterModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './personal-list.component.html',
  styleUrls: ['./personal-list.component.scss']
})
export class PersonalListComponent {
  @Input() apps: { title: string, url: string }[] = [];

  @ViewChildren('swiperContainer', { read: ElementRef }) swiperContainers!: QueryList<ElementRef>;
  readonly dialog = inject(MatDialog);

  isActive = [false, false];

  openDialogLinks() {
    const dialogRef = this.dialog.open(DialogContentExampleDialog5, {
      width: '100%',
      maxWidth: '770px',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  toggleDropdown(event: Event, index: number): void {
    event.preventDefault();

    this.isActive = this.isActive.map((_, i) => i === index ? !this.isActive[i] : false);
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event): void {
    const clickedInside = (event.target as HTMLElement).closest('.personal-list__item');

    if (!clickedInside) {
      this.isActive = [false, false];
    }
  }

  ngAfterViewInit() {
    this.swiperContainers.forEach((container) => {
      this.initializeSwiper(container.nativeElement);
    });
  }

  initializeSwiper(swiperEl: any): void {
    const swiperParams = {
      slidesPerView: 7,
      direction: 'vertical',
      navigation: true,
      loop: false,
      cssMode: false,
      speed: 500,
      spaceBetween: 0,
      simulateTouch: true,
    };

    // Assign the parameters to the container
    Object.assign(swiperEl, swiperParams);

    // Initialize Swiper with the parameters
    swiperEl.initialize();
  }
}

@Component({
  selector: 'dialog-content-example-dialog5',
  templateUrl: '../content-exemple/dialog-content-exemple-dialog5.html',
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, MatTab, MatTabGroup, MatFormField, MatInput, MatLabel, CommonModule, FormsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogContentExampleDialog5 {
  isEditing: { [key: string]: boolean } = {
    item1: false,
    item2: false,
    item3: false,
    item4: false,
  };

  newLink = {
    name: '',
    url: ''
  };

  openEditForm(itemId: string): void {
    Object.keys(this.isEditing).forEach(key => this.isEditing[key] = false);

    this.isEditing[itemId] = true;
  }

  closeEditForm(itemId: string): void {
    this.isEditing[itemId] = false;
  }

  submitEditForm(itemId: string): void {
    this.isEditing[itemId] = false;

    /** TODO Backend **/
  }

  clearAddForm(): void {
    this.newLink.name = '';
    this.newLink.url = '';
  }

  submitAddForm() {
    /** TODO Backend **/

    this.newLink.name = '';
    this.newLink.url = '';
  }
}
