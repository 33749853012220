import { APP_INITIALIZER, ApplicationConfig, ErrorHandler, importProvidersFrom, provideZoneChangeDetection, inject } from '@angular/core';
import { provideRouter, Router } from '@angular/router';
import { NgxPiwikProModule, NgxPiwikProRouterModule } from "@piwikpro/ngx-piwik-pro";
import player from 'lottie-web';
import { provideCacheableAnimationLoader, provideLottieOptions } from 'ngx-lottie';

import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withFetch,
  withInterceptors,
  withInterceptorsFromDi
} from '@angular/common/http';

import { BrowserModule } from "@angular/platform-browser";
import { provideNoopAnimations } from "@angular/platform-browser/animations";
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalService
} from "@azure/msal-angular";
import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication
} from "@azure/msal-browser";
import { environment } from "../environments/environment";
import { routes } from './app.routes';
import { apiInterceptor } from "./interceptors/api.interceptor";
import { serverErrorInterceptor } from './interceptors/server-error.interceptor';
import * as Sentry from "@sentry/angular";


export function loggerCallback(logLevel: LogLevel, message: string) {
  environment.enableLogging && console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.msalConfig.auth.clientId,
      authority: environment.msalConfig.auth.authority,
      redirectUri: '/',
      postLogoutRedirectUri: '/',
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Error,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(
    environment.azureApiConfig.uri,
    environment.azureApiConfig.scopes
  );
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      prompt: 'select_account',
      authority: environment.msalConfig.auth.authority,
      scopes: [...environment.azureApiConfig.scopes],
    },
    loginFailedRoute: '/',
  };
}

export function MSALInitializeFactory(msalService: MsalService): () => Promise<void> {
  return () => msalService.instance.initialize();
}

// Une fonction pour créer conditionnellement le provider
function providePiwikPro() {
  if (environment.piwikProConfig?.id) {
    const providePiwikPro = [
      importProvidersFrom(NgxPiwikProModule.forRoot(environment.piwikProConfig?.id, environment.piwikProConfig?.url), NgxPiwikProRouterModule),
    ];
    return providePiwikPro;
  }
  return []; // Pas de providers si la condition n'est pas remplie
}

export const appConfig: ApplicationConfig = {
  providers: [
    ...providePiwikPro(),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideLottieOptions({ player: () => player }),
    provideCacheableAnimationLoader(),
    provideRouter(routes),
    importProvidersFrom(
      BrowserModule,
    ),
    provideAnimationsAsync(),
    provideNoopAnimations(),
    provideHttpClient(withInterceptorsFromDi(), withFetch(), withInterceptors([apiInterceptor, serverErrorInterceptor])),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: MSALInitializeFactory,
      deps: [MsalService],
      multi: true,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ]
};
