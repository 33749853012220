import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { ApiService } from '../../../services/api.service';
import { LoadingOverlayComponent } from '../../loading-overlay/loading-overlay.component';

@Component({
  selector: 'dialog-send-email',
  templateUrl: './dialog-send-email.component.html',
  styleUrl: './dialog-send-email.component.scss',
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, MatFormField, MatLabel, MatInput, NgClass, NgIf, LoadingOverlayComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogSendEmailComponent {
  isLoading: boolean = false;
  errorMessage: string | null = null; // Message d'erreur

  constructor(
    private apiService: ApiService,
    private dialogRef: MatDialogRef<DialogSendEmailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(MAT_DIALOG_DATA) public url: any,
    private cdr: ChangeDetectorRef
  ) {}

  async sendEmail(email: string) {
  this.errorMessage = null;
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  if (emailPattern.test(email)) {
    this.isLoading = true;
    this.cdr.detectChanges(); // Forcer la mise à jour de la vue
    try {
      if (this.data.slug) {
        await this.apiService.sharePageByEmail(this.data.slug, email);
      } else if (this.url) {
        await this.apiService.shareDocumentByEmail(this.url, email);
      }
      this.dialogRef.close();
    } catch (error) {
      console.error('Erreur lors de l\'envoi de l\'email:', error);
      this.errorMessage = 'Une erreur est survenue lors de l\'envoi de l\'email. Veuillez réessayer.';
    } finally {
      this.isLoading = false;
      this.cdr.detectChanges(); // Forcer la mise à jour de la vue
    }
  } else {
    this.errorMessage = 'Le format de l\'email est invalide. Veuillez saisir une adresse email valide.';
  }
}
}
