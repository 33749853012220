// configuration d'intégration (Azure)
export const environment = {
  production: false,
  enableLogging: true,
  cacheExpiration: 3600, //seconds
  apiUrl: 'https://vnfetmoi-api-int2.vnf.fr/wp-json',
  msalConfig: {
    // Configuration Azure VNF INT
    auth: {
      clientId: 'a35c2f97-b6e6-4450-b518-5a4a5478215a',
      authority: 'https://login.microsoftonline.com/e656fd6a-aaca-470b-a737-8d19eb341c97/v2.0',
    },
  },
  azureApiConfig: {
    scopes: ['User.Read', 'User.Read.All', 'profile', 'openid', 'Calendars.readWrite'],
    uri: 'https://graph.microsoft.com/v1.0/me',
  },
  elasticSearchConfig: {
    documentDetail: true
  },
  piwikProConfig: {
    // id: '22c09dc8-8747-4541-a219-cbbc24d2db8d',
    // url: 'https://vnf.piwik.pro'
    id: '',
    url: ''
  },
  sentry: {
    dsn: 'https://fc1d871661c66e10889dcb9820a0b98a@o347068.ingest.us.sentry.io/4508455556743168'
  }
};